import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import '../../index.css';
import { Helmet } from 'react-helmet';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';
import Auth from '../../services/Auth';
import BBAnalytics from '../../services/BBAnalytics';
import Content from '../../constants/Content';
import Environment from '../../services/Environment';
import MetaTagService from '../../services/MetaTagService';
import CartUtils from '../../services/CartUtils';
import BitsyApollo from '../../services/BitsyApollo';
import SentryService from '../../services/Sentry';

import Home from '../home';

import {
  MyAccount,
  SubscriptionDetails,
  PaymentDetails,
  DueDateCalculator,
  WeekByWeek,
  ThankYou,
  Shop,
  Faq,
  About,
  BumpFund,
  LoyaltyProgram,
  Contact,
  Partnerships,
  SignIn,
  ForgotPassword,
  ResetPassword,
  BreastPump,
  Careers,
  TermsOfService,
  PrivacyPolicy,
  SecurityDisclosure,
  CorpGifting,
  Press,
  ErrorPage,
  Page404,
  Page403,
  Product,
  Review,
  Checkout,
  AdminPortalEntry,
  ShopRedirect,
  Blog,
  SelectSubscription,
  BreastPumpBrowse,
  BreastPumpPostPurchase,
  BreastPumpThankYou,
  CorporatePortal,
  CorporateVerification,
  PrenatalVitamins,
  CustomizeSubscription,
  CustomizeSubscriptionLandingPage,
  ViewRegistry,
  FindRegistry,
  ManageRegistry,
  CreateRegistry,
  PatientGrievance,
  PatientRights,
  PrivacyNotice,
  Warranty,
  AssignmentOfBenefits,
  Covid19,
  MyOrder,
  EditPayment,
  Giveaway,
  ReferAFriend,
  Extensions,
  CustomerReviews,
  Offers,
  GiveawayTerms,
  ImageEditor,
  BabyNames,
} from './splitRoutes';

import {
  AdminNav,
  Navigation,
  PartnershipNavigation,
  withTracker,
  WteFooter,
  PnmagFooter,
  Footer,
  TopBanner,
  VisibilityPlaceholder,
} from '../../components';

SentryService.initTime();

// Call once before the interval to get a new token on site load before any other requests are made
if (Auth.hasToken() && !Auth.isAdmin()) {
  BitsyApollo.refreshUserToken();
}
window.setInterval(() => {
  // Fetch new token every 10 minutes
  if (Auth.hasToken() && !Auth.isAdmin()) {
    BitsyApollo.refreshUserToken();
  }
}, 1000 * 60 * 10);

const myStatsigClient = new StatsigClient(
  Environment.getStatsigKey,
  {},
  { environment: { tier: Environment.getStatsigEnvironment } }, // (optional) Configure SDK via StatsigOptions here
);

myStatsigClient.initializeAsync();
window.statsigClient = myStatsigClient;

class App extends React.Component {
  _isMounted = false; // eslint-disable-line no-underscore-dangle

  constructor(props) {
    super(props);
    this.state = {
      itemsInCart: 0,
      isCorporateUser: false,
      showFooter: false,
    };
    // Check for UTM values and store them
    const urlParamString = window.location.search;
    const urlParams = new URLSearchParams(urlParamString);
    if (
      urlParams.has('utm_source') ||
      urlParams.has('utm_medium') ||
      urlParams.has('utm_campaign')
    ) {
      localStorage.setItem('utm_source', urlParams.get('utm_source'));
      localStorage.setItem('utm_medium', urlParams.get('utm_medium'));
      localStorage.setItem('utm_campaign', urlParams.get('utm_campaign'));
    }
  }

  async componentDidMount() {
    this._isMounted = true; // eslint-disable-line no-underscore-dangle
    this.onCartUpdate();

    // MaxBounty check and storage
    const urlParamString = window.location.search;

    const urlParams = new URLSearchParams(urlParamString);

    if (urlParams.get('subid') && urlParams.get('clickid')) {
      localStorage.setItem('maxBountyClickId', urlParams.get('clickid'));
    }

    await this.getAndSetUser();

    this.determineAndSetIsCorporateUser();

    if (this.state.userData) {
      await myStatsigClient.updateUserAsync({
        userID: this.state.userData._id,
        email:
          this.state.userData.email || this.state.userData.guestCheckoutEmail,
      });
    }

    this.runAnalyticsInit();
  }

  componentWillUnmount() {
    this._isMounted = false; // eslint-disable-line no-underscore-dangle
  }

  setCorpPortLastAction = () => {
    localStorage.setItem('corpPortLastAction', new Date());
  };

  onLogIn = async () => {
    await this.getAndSetUser();
    this.determineAndSetIsCorporateUser();
    await this.sendUserDataToMetaPixel();
  };

  getAndSetUser = async (data) => {
    if (Auth.isLoggedIn()) {
      const user = await BitsyApollo.userInfoQuery(data);
      if (user && user.data && user.data.userInfo) {
        const userData = user.data.userInfo;
        this.runProfitWellInit(userData);
        this.setState({
          userData,
        });
      }
    }
  };

  runProfitWellInit = (userData) => {
    if (window.profitwell) {
      // eslint-disable-next-line camelcase
      window.profitwell('start', { user_email: userData.email });
    }
    return null;
  };

  determineAndSetIsCorporateUser = () => {
    if (this.state.userData && this.state.userData.accountType) {
      this.setState((prevState) => ({
        isCorporateUser: prevState.userData.accountType === 'corporate',
      }));
    }
  };

  sendUserDataToMetaPixel = async () => {
    const { isCorporateUser, userData } = this.state;
    if (!isCorporateUser && userData && userData.name) {
      userData.userId = userData._id;
      BBAnalytics.addAdvancedMatchingDataToMetaPixel(userData);
    }
  };

  runAnalyticsInit = async () => {
    BBAnalytics.runTheInits();
    this.sendUserDataToMetaPixel();
  };

  // Returning a function here is important.
  // It is what is being called when this method is called.
  // First time it's called, lastCall = 0.
  // Subsequent calls, lastCall is being set by the return function.
  // The return function is determining whether to call setCorpLastAction or not.
  throttleAction = (action, delay) => {
    let lastCall = 0;
    return () => {
      // eslint-disable-line func-names
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return null;
      }
      lastCall = now;
      return action();
    };
  };

  onCartUpdate = async () => {
    const customerId = localStorage.getItem('customerId');
    const hasToken = Auth.hasToken();
    const isLoggedIn = Auth.isLoggedIn();
    let itemsInCart;
    if (isLoggedIn || hasToken) {
      itemsInCart = await CartUtils.cartItemCountQuery(customerId);
    } else if (!isLoggedIn) {
      const tempCart = JSON.parse(localStorage.getItem('tempCart'));
      if (tempCart && (tempCart.productsAndDetails || tempCart.subscription)) {
        itemsInCart = tempCart.productsAndDetails.length;
        if (tempCart.subscription) {
          itemsInCart += 1;
        }
      } else {
        itemsInCart = 0;
      }
    }
    if (this._isMounted) this.setState({ itemsInCart }); // eslint-disable-line no-underscore-dangle
    return itemsInCart;
  };

  wrappedAdminPortalEntry = withTracker(AdminPortalEntry);

  wrappedSignIn = withTracker(SignIn, {
    onCartUpdate: this.onCartUpdate,
    onLogIn: this.onLogIn,
  });

  wrappedForgotPassword = withTracker(ForgotPassword);

  wrappedResetPassword = withTracker(ResetPassword);

  wrappedCorporateVerification = withTracker(CorporateVerification);

  wrappedPrenatalVitamins = withTracker(PrenatalVitamins, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedMyAccount = withTracker(MyAccount, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedSubscriptionDetails = withTracker(SubscriptionDetails);

  wrappedPaymentDetails = withTracker(PaymentDetails, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedDueDateCalculator = withTracker(DueDateCalculator);

  wrappedWeekByWeek = withTracker(WeekByWeek, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedSelectSubscription = withTracker(SelectSubscription, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedReview = withTracker(Review, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedHome = withTracker(Home, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedThankYou = withTracker(ThankYou, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedCheckout = withTracker(Checkout, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedFaq = withTracker(Faq);

  wrappedPartnerships = withTracker(Partnerships);

  wrappedAbout = withTracker(About);

  wrappedBumpFund = withTracker(BumpFund);

  wrappedPress = withTracker(Press);

  wrappedLoyaltyProgram = withTracker(LoyaltyProgram);

  wrappedContact = withTracker(Contact);

  wrappedCareers = withTracker(Careers);

  wrappedBreastPump = withTracker(BreastPump, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedPatientGrievance = withTracker(PatientGrievance);

  wrappedPatientRights = withTracker(PatientRights);

  wrappedPrivacyNotice = withTracker(PrivacyNotice);

  wrappedWarranty = withTracker(Warranty);

  wrappedAssignmentOfBenefits = withTracker(AssignmentOfBenefits);

  wrappedCovid19 = withTracker(Covid19);

  wrappedErrorPage = withTracker(ErrorPage);

  wrappedTermsOfService = withTracker(TermsOfService);

  wrappedPrivacyPolicy = withTracker(PrivacyPolicy);

  wrappedSecurityDisclosure = withTracker(SecurityDisclosure);

  wrappedCorpGifting = withTracker(CorpGifting);

  wrappedPage403 = withTracker(Page403);

  wrappedBlog = withTracker(Blog);

  wrappedShop = withTracker(Shop, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedShopRedirect = withTracker(ShopRedirect, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedProduct = withTracker(Product, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedPage404 = withTracker(Page404, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedBreastPumpBrowse = withTracker(BreastPumpBrowse, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedBreastPumpPostPurchase = withTracker(BreastPumpPostPurchase, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedBreastPumpThankYou = withTracker(BreastPumpThankYou, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedCorporatePortal = withTracker(CorporatePortal, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedCustomizeSubscription = withTracker(CustomizeSubscription);

  wrappedCustomizeSubscriptionLandingPage = withTracker(
    CustomizeSubscriptionLandingPage,
  );

  wrappedViewRegistry = withTracker(ViewRegistry, {
    onCartUpdate: this.onCartUpdate,
  });

  wrappedFindRegistry = withTracker(FindRegistry);

  wrappedManageRegistry = withTracker(ManageRegistry);

  wrappedCreateRegistry = withTracker(CreateRegistry);

  wrappedMyOrder = withTracker(MyOrder);

  wrappedEditPayment = withTracker(EditPayment);

  wrappedGiveaway = withTracker(Giveaway);

  wrappedGiveawayTerms = withTracker(GiveawayTerms);

  wrappedReferAFriend = withTracker(ReferAFriend);

  wrappedExtensions = withTracker(Extensions);

  wrappedCustomerReviews = withTracker(CustomerReviews);

  wrappedOffers = withTracker(Offers);

  wrappedImageEditor = withTracker(ImageEditor);

  wrappedBabyNames = withTracker(BabyNames);

  renderNavigation = () => {
    if (this.props.location.pathname.includes('/my-order')) {
      return null;
    }

    if (Environment.store !== 'bump' && Environment.store !== 'bitsy') {
      return <PartnershipNavigation itemsInCart={this.state.itemsInCart} />;
    }

    return (
      <>
        {this.state.userData ? (
          <div className='banner'>
            <TopBanner
              userData={this.state.userData}
              getAndSetUser={this.getAndSetUser}
            />
          </div>
        ) : null}
        <Navigation
          itemsInCart={this.state.itemsInCart}
          isCorporateUser={this.state.isCorporateUser}
          isHomepage={this.props.location.pathname === '/'}
        />
      </>
    );
  };

  renderFooter = () => {
    if (
      this.props.location.pathname.includes('/free-breast-pump') ||
      this.props.location.pathname.includes('/guest/checkout/review') ||
      this.props.location.pathname.includes('/checkout/')
    ) {
      return null;
    }
    if (Environment.store === 'wtebump') {
      return <WteFooter />;
    }
    if (Environment.store === 'pnmbump') {
      return <PnmagFooter />;
    }
    return <Footer />;
  };

  onVisibilityFooter = (isVisible) => {
    if (isVisible && !this.state.showFooter) {
      this.setState({
        showFooter: true,
      });
    }
  };

  windowLocationPnm = () => {
    window.location = 'https://pnmag.bumpboxes.com';
    return null;
  };

  render() {
    // This Helmet tag defines the default meta tags to be served. It also
    // defines the title template to be followed by child pages.

    const isRedirect = this.props.history.action === 'REPLACE';

    return (
      <StatsigProvider client={myStatsigClient}>
        <div
          onMouseMove={
            this.state.isCorporateUser
              ? this.throttleAction(this.setCorpPortLastAction, 5000)
              : null
          }
        >
          <Helmet
            titleTemplate={`%s${Content.meta.defaultTitleSuffix}`}
            defaultTitle={
              Content.meta.defaultTitlePrefix + Content.meta.defaultTitleSuffix
            }
          >
            <meta
              name='description'
              content={Content.meta.defaultDescription}
            />
            <link
              rel='canonical'
              href={MetaTagService.getCanonicalUrl(
                this.props.location.pathname,
              )}
            />

            {isRedirect ? (
              <meta
                name='prerender-header'
                content={`Location: ${MetaTagService.getCanonicalUrl(
                  this.props.location.pathname,
                )}`}
              />
            ) : null}

            {isRedirect ? (
              <meta name='prerender-status-code' content='301' />
            ) : null}

            <meta
              property='og:site_name'
              content={Content.names.fancyNamePlural}
            />
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='website' />
            <meta
              property='og:title'
              content={MetaTagService.getTemplatedTitle(
                Content.meta.defaultTitlePrefix,
              )}
            />
            <meta
              property='og:description'
              content={Content.meta.defaultDescription}
            />
            <meta
              property='og:image'
              content={Content.names.mainUrl + Content.meta.defaultImg}
            />
            <meta
              property='og:url'
              content={MetaTagService.getCanonicalUrl(
                this.props.location.pathname,
              )}
            />

            <meta
              name='twitter:title'
              content={MetaTagService.getTemplatedTitle(
                Content.meta.defaultTitleSuffix,
              )}
            />
            <meta
              name='twitter:description'
              content={Content.meta.defaultDescription}
            />
            <meta
              name='twitter:image'
              content={Content.names.mainUrl + Content.meta.defaultImg}
            />
          </Helmet>
          {Auth.isAdmin() &&
          !/admin-portal-entry/.test(window.location.href) ? (
            <AdminNav />
          ) : null}
          {Auth.isAdmin() &&
          !/admin-portal-entry/.test(window.location.href) ? (
            <div className='admin-nav-placeholder' />
          ) : null}
          {this.renderNavigation()}
          {!this.props.location.pathname.includes('/my-order') ? (
            <div className='mobileTopPadding' />
          ) : null}
          <main>
            <Switch>
              <Route exact path='/' component={this.wrappedHome} />
              <Redirect
                from='/:url*(/+)'
                to={
                  this.props.location.pathname.slice(0, -1) +
                  window.location.search
                }
              />
              <Route
                exact
                path='/admin-portal-entry/customer/:customerFirstName/:customerLastName/:customerId'
                component={this.wrappedAdminPortalEntry}
              />
              <Route exact path='/signin' component={this.wrappedSignIn} />
              <Route
                exact
                path='/forgot-password'
                component={this.wrappedForgotPassword}
              />
              <Route
                exact
                path='/legacy/reset-password/:resetPasswordCode'
                component={this.wrappedResetPassword}
              />
              <Route
                exact
                path='/reset-password/:resetPasswordCode'
                component={this.wrappedResetPassword}
              />
              <Route
                exact
                path='/my-account'
                component={this.wrappedMyAccount}
              />
              <Route
                exact
                path='/subscription-details/:subscriptionId'
                component={this.wrappedSubscriptionDetails}
              />
              <Route
                exact
                path='/payment-details/:subscriptionId'
                component={this.wrappedPaymentDetails}
              />
              <Route
                exact
                path='/due-date-calculator'
                component={this.wrappedDueDateCalculator}
              />
              <Route
                exact
                path='/week-by-week'
                component={this.wrappedWeekByWeek}
              />
              <Route
                exact
                path='/week-by-week/:week'
                component={this.wrappedWeekByWeek}
              />
              <Route
                exact
                path='/cart-empty'
                component={this.wrappedSelectSubscription}
              />
              <Route
                exact
                path='/gift'
                component={this.wrappedSelectSubscription}
              />
              <Route
                exact
                path='/purchase/thank-you/:purchaseId'
                component={this.wrappedThankYou}
              />
              <Route
                exact
                path='/guest/checkout/review'
                component={this.wrappedReview}
              />
              <Route
                exact
                path='/checkout/review'
                component={this.wrappedReview}
              />
              <Route
                exact
                path='/checkout/shipping-and-payment'
                component={this.wrappedCheckout}
              />
              <Route exact path='/garage' component={this.wrappedHome} />
              <Route
                exact
                path='/momentsofmeaning'
                component={this.wrappedHome}
              />
              <Route exact path='/ltsi' component={this.wrappedHome} />
              <Route exact path='/housewives' component={this.wrappedHome} />
              <Route
                exact
                path='/membership/:couponCode'
                component={this.wrappedHome}
              />
              <Route exact path='/mb' component={this.wrappedHome} />
              <Route
                exact
                path='/referred/:referralCode'
                component={this.wrappedHome}
              />
              <Route
                path='/faq'
                component={() => {
                  window.location.href = 'https://support.bumpboxes.com';
                  return null;
                }}
              />
              <Route
                exact
                path='/partnerships'
                component={this.wrappedPartnerships}
              />
              <Route exact path='/about-us' component={this.wrappedAbout} />
              <Route exact path='/bump-fund' component={this.wrappedBumpFund}>
                <Redirect to='/' />
              </Route>
              ,
              <Route exact path='/press' component={this.wrappedPress} />
              <Route
                exact
                path='/reviews'
                component={this.wrappedCustomerReviews}
              />
              <Route exact path='/offers/:step' component={this.wrappedOffers}>
                <Redirect to='/tools/pregnancy-announcement' />
              </Route>
              <Redirect
                from='/offers'
                key='/offers'
                to='/tools/pregnancy-announcement'
              />
              ,
              <Route
                exact
                path='/loyalty-program'
                component={this.wrappedLoyaltyProgram}
              />
              <Route exact path='/contact' component={this.wrappedContact} />
              <Route exact path='/careers' component={this.wrappedCareers} />
              <Route
                exact
                path='/patient-grievance-procedure'
                component={this.wrappedPatientGrievance}
              />
              <Route
                exact
                path='/patient-rights-and-responsibilities'
                component={this.wrappedPatientRights}
              />
              <Route
                exact
                path='/privacy-notice-hipaa'
                component={this.wrappedPrivacyNotice}
              />
              <Route
                exact
                path='/dme-warranty'
                component={this.wrappedWarranty}
              />
              <Route
                exact
                path='/assignment-of-benefits'
                component={this.wrappedAssignmentOfBenefits}
              />
              <Route exact path='/covid19' component={this.wrappedCovid19} />
              <Route exact path='/error' component={this.wrappedErrorPage} />
              <Route
                exact
                path='/terms-of-service'
                component={this.wrappedTermsOfService}
              />
              <Route
                exact
                path='/privacy-policy'
                component={this.wrappedPrivacyPolicy}
              />
              <Route
                exact
                path='/security-disclosure-policy'
                component={this.wrappedSecurityDisclosure}
              />
              <Route
                exact
                path='/corporate-gifting'
                component={this.wrappedCorpGifting}
              />
              <Route
                exact
                path='/error/unauthorized'
                component={this.wrappedPage403}
              />
              <Route exact path='/review' component={this.wrappedReview} />
              <Route exact path='/index.html' component={this.wrappedHome} />
              <Redirect
                exact
                from='/blog/post/:blogName/amp/'
                to='/blog/post/:blogName'
              />
              <Redirect
                exact
                from='/blog/category/:categoryName/'
                to='/blog/category/:categoryName/1'
              />
              <Route path='/blog/post/:blogName' component={this.wrappedBlog} />
              <Route
                path='/blog/page/:pageNumber'
                component={this.wrappedBlog}
              />
              <Route
                path='/blog/category/:category/:pageNumber'
                component={this.wrappedBlog}
              />
              <Redirect from='/gift-subscription' to='/' />
              <Redirect from='/check-out' to='/shop/all' />
              <Route exact path='/blog/' component={this.wrappedBlog} />
              <Route exact path='/blog' component={this.wrappedBlog} />
              <Route exact path='/pnmag/' component={this.windowLocationPnm} />
              <Route exact path='/pnmag' component={this.windowLocationPnm} />
              <Redirect from='/peoria*' to='/careers' />
              {Environment.store === 'bump'
                ? [
                    <Route
                      exact
                      path='/prenatal-vitamins'
                      key='/prenatal-vitamins'
                      component={this.wrappedPrenatalVitamins}
                    />,
                    <Redirect
                      from='/free-prenatal-vitamins'
                      key='/free-prenatal-vitamins'
                      to='/prenatal-vitamins'
                    />,
                    <Redirect
                      from='/prenatals'
                      key='/prenatals'
                      to='/prenatal-vitamins'
                    />,
                    <Route
                      exact
                      path='/view-registry/:shareCode'
                      key='/view-registy/:shareCode'
                      component={this.wrappedViewRegistry}
                    />,
                    <Route
                      exact
                      path='/find-registry'
                      key='/find-registy'
                      component={this.wrappedFindRegistry}
                    />,
                    <Route
                      exact
                      path='/manage-registry'
                      key='/manage-registy'
                      component={this.wrappedManageRegistry}
                    />,
                    <Route
                      exact
                      path='/create-registry'
                      key='/create-registy'
                      component={this.wrappedCreateRegistry}
                    />,
                    <Redirect
                      from='/subscribe/select'
                      key='/subscribe/select'
                      to='/products/six-month-pregnancy-subscription-box'
                    />,
                  ]
                : null}
              {Environment.store === 'bump'
                ? [
                    <Route
                      exact
                      path='/free-breast-pump/post-purchase'
                      key='/free-breast-pump/post-purchase'
                      component={this.wrappedBreastPumpPostPurchase}
                    />,
                    <Route
                      exact
                      path='/free-breast-pump/browse'
                      key='/free-breast-pump/browse'
                      component={this.wrappedBreastPumpBrowse}
                    />,
                    <Route
                      exact
                      path='/free-breast-pump/browse/:brand'
                      key='/free-breast-pump/browse/:brand'
                      component={this.wrappedBreastPumpBrowse}
                    />,
                    <Route
                      exact
                      path='/free-breast-pump/thank-you'
                      key='/free-breast-pump/thank-you'
                      component={this.wrappedBreastPumpThankYou}
                    />,
                    <Route
                      exact
                      path='/free-breast-pump/:step/:brand?'
                      key='/free-breast-pump/:step'
                      component={this.wrappedBreastPump}
                    />,
                    <Redirect
                      from='/free-breast-pump'
                      key='/free-breast-pump'
                      to='/free-breast-pump/step-1'
                    />,
                    <Redirect
                      from='/pump'
                      key='/pump'
                      to='/free-breast-pump/step-1'
                    />,
                    <Redirect
                      from='/pumps'
                      key='/pumps'
                      to='/free-breast-pump/step-1'
                    />,
                  ]
                : null}
              {Environment.store === 'bump' || Environment.store === 'bitsy'
                ? [
                    <Route
                      exact
                      path='/shop/:parentCategory'
                      key='/shop/:parentCategory'
                      component={this.wrappedShopRedirect}
                    />,
                    <Route
                      exact
                      path='/shop/:parentCategory/:childCategory'
                      key='/shop/:parentCategory/:childCategory'
                      component={this.wrappedShopRedirect}
                    />,
                    <Route
                      exact
                      path='/shop/:parentCategory/:childCategory/:sort'
                      key='/shop/:parentCategory/:childCategory/:sort'
                      component={this.wrappedShopRedirect}
                    />,
                    <Redirect from='/shop/' to='/shop/all' key='/shop/' />,
                    <Redirect from='/shop' to='/shop/all' key='/shop' />,
                    <Redirect
                      from='/subscriptions'
                      to='/subscribe/select'
                      key='/subscribe/select'
                    />,
                    <Route
                      exact
                      path='/product-category/:oldBumpCategory'
                      key='/product-category/:oldBumpCategory'
                      component={this.wrappedShopRedirect}
                    />,
                    <Route
                      exact
                      path='/product-category/shop-all-products'
                      key='/product-category/shop-all-products'
                      component={this.wrappedShop}
                    />,
                    <Route
                      exact
                      path='/products/:productUrlName'
                      key='/products/:productUrlName'
                      component={this.wrappedProduct}
                    />,
                    <Route
                      exact
                      path='/product/:productUrlName'
                      key='/product/:productUrlName'
                      component={this.wrappedProduct}
                    />,
                    <Route
                      exact
                      path='/portal/:companyCode'
                      key='/portal/:companyCode'
                      component={this.wrappedCorporatePortal}
                    />,
                    <Route
                      exact
                      path='/signup/:signUpCode'
                      key='/signup/:signUpCode'
                      component={this.wrappedSignIn}
                    />,
                    <Route
                      exact
                      path='/corporate-verification/:corporateVerificationCode'
                      key='/corporate-verification/:corporateVerificationCode'
                      component={this.wrappedCorporateVerification}
                    />,
                    <Route
                      exact
                      path='/customize-subscription/:subscriptionId'
                      key='/customize-subscription/:subscriptionId'
                      component={this.wrappedCustomizeSubscription}
                    />,
                    <Route
                      exact
                      path='/customize-subscription'
                      key='/customize-subscription'
                      component={this.wrappedCustomizeSubscriptionLandingPage}
                    />,
                  ]
                : null}
              <Route
                exact
                path='/subscribe/select'
                component={this.wrappedSelectSubscription}
              />
              <Route
                path='/my-order/:confirmationCode'
                component={this.wrappedMyOrder}
              />
              <Route
                path='/edit-payment/:subConfirmationCode'
                component={this.wrappedEditPayment}
              />
              <Route path='/giveaway' component={this.wrappedGiveaway} />
              <Route
                exact
                path='/giveaway-terms'
                component={this.wrappedGiveawayTerms}
              />
              <Route
                path='/refer-a-friend'
                component={this.wrappedReferAFriend}
              />
              <Redirect from='/life-insurance' to='/' key='life-insurance' />,
              <Route
                path='/tools/pregnancy-announcement'
                render={(props) =>
                  React.createElement(this.wrappedImageEditor, {
                    userData: this.state.userData,
                    location: props.location,
                  })
                }
              />
              <Route
                path='/tools/baby-names'
                render={(props) =>
                  React.createElement(this.wrappedBabyNames, {
                    userData: this.state.userData,
                    location: props.location,
                  })
                }
              />
              <Route path='/extensions' component={this.wrappedExtensions} />
              <Route component={this.wrappedPage404} />
            </Switch>
          </main>
          <VisibilitySensor
            offset={{ top: 25 }}
            partialVisibility
            onChange={this.onVisibilityFooter}
            active={!this.state.showFooter}
          >
            {this.state.showFooter ? (
              <footer>{this.renderFooter()}</footer>
            ) : (
              <VisibilityPlaceholder />
            )}
          </VisibilitySensor>
        </div>
      </StatsigProvider>
    );
  }
}

export default withRouter(App);
