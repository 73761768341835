import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ExternalImage from '../product/ExternalImage';
import ProductReviewRating from '../product-review-rating';
import './style.scss';

import BitsyApollo from '../../services/BitsyApollo';
import Environment from '../../services/Environment';
import CartUtils from '../../services/CartUtils';
import Yotpo from '../../services/Yotpo';

export default function PreCheckoutPopup(props) {
  const { exitModal } = props;
  const [promoProducts, setPromoProducts] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    function setProductsOrExitModal(products) {
      if (products && products.length) {
        setPromoProducts(products);
      } else {
        exitModal();
      }
    }

    async function fetchProducts() {
      const data = {
        isRecurringAddOnPromo: props.isRecurringAddOnPromo,
        provider: Environment.store,
        productAddedToCart: props.productAddedToCart,
      };
      const products = await BitsyApollo.getPreCheckoutPromoProduct(data);
      if (products && products.length > 0) {
        props.updateTitle(products[0].ownPromoData.title);
      }
      setProductsOrExitModal(products);
    }
    fetchProducts();
  }, [exitModal, props.isRecurringAddOnPromo]);

  useEffect(() => {
    (async () => {
      Yotpo.runScriptWhenIdIsPresent('preCheckoutPromo');
    })();
  }, []);

  const addToCart = async (product) => {
    setIsSubmitDisabled(true);
    const variation = 'NONE';
    const isPromo = true;
    const list = 'Pre-Cart Promo';
    if (props.addToCart) {
      props.addToCart(product, variation, isPromo, list);
    } else {
      const newState = {};

      const shouldShowPreCheckoutPromo = false;

      const res = await CartUtils.addProductToCart(
        product,
        variation,
        newState,
        Environment.store,
        isPromo,
        null,
        null,
        null,
        shouldShowPreCheckoutPromo,
        list,
      );

      if (res) {
        props.handleAddPreCheckoutPromo(res);
      }
    }
  };

  const renderPromoItems = () => {
    if (!promoProducts || !promoProducts.length) {
      return null;
    }

    return promoProducts.map((product) => (
      <React.Fragment key={product._id}>
        {product.ownPromoData.header ? (
          <Row>
            <Col>
              <h2 className='productHeader'>{product.ownPromoData.header}</h2>
            </Col>
          </Row>
        ) : null}
        {product.ownPromoData.subHeader ? (
          <Row>
            <Col>
              <h3 className='productSubHeader mt-2'>
                {product.ownPromoData.subHeader}
              </h3>
            </Col>
          </Row>
        ) : null}
        <Row className='productDetailsPromo' id={props.id}>
          <Col xs={6} sm={6} md={6} lg={6} className='product-image productCol'>
            <ExternalImage
              src={
                product.media && product.media.length
                  ? product.media[0].url
                  : 'https://s3.amazonaws.com/bitsy-product-images/default-product-image.png'
              }
              alt={`${product.name} example for cart confirmation`}
              sizes='(max-width: 414px) 50vw, 25vw'
            />
          </Col>
          <Col className='productCol' xs={6} sm={6} md={6} lg={6}>
            <h3 className='productName'>{product.name}</h3>
            <div className='productBrand'>{product.brand}</div>
            <ProductReviewRating product={product} />
            <div>
              {product.actualCost !== product.ownPromoData.cost ? (
                <span className='priceSlashed'>${product.actualCost}</span>
              ) : null}
              <span className='price'>${product.ownPromoData.cost}</span>
            </div>
          </Col>
          <Col className='addOfferButtonCol' xs={12} sm={12} md={12} lg={12}>
            <Button
              className='addOfferButton'
              onClick={() => addToCart(product)}
              variant='primary'
              disabled={isSubmitDisabled}
            >
              {product.ownPromoData.buttonText}
            </Button>
          </Col>
          <Col className='noOfferButtonCol' xs={12} sm={12} md={12} lg={12}>
            <Button
              className='noOfferButton'
              variant='link'
              style={{ width: '100%' }}
              onClick={props.exitModal}
            >
              No Thanks
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    ));
  };

  return (
    <div id='preCheckoutPromo' className='preCheckoutPromo'>
      {renderPromoItems()}
    </div>
  );
}
