import { React, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './style.css';

const renderHelpBlock = (isInvalid, text) => {
  if (isInvalid) {
    return <div className='help-block'>{text}</div>;
  }
  return null;
};

const FieldGroup = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const onKeyPress = (target) => {
    if (props.onKeyPress && target.charCode === 13) {
      return props.onKeyPress();
    }
    return null;
  };

  const toggleShowPassword = (target) => {
    if (!target.charCode || target.charCode === 13) {
      setShowPassword((prevState) => !prevState);
    }
  };

  const isInvalid =
    props.formValuesValidationState &&
    !props.formValuesValidationState[props.name] &&
    props.hasBeenSubmitted;

  return (
    <Form.Group
      controlId={props.controlId}
      validated={isInvalid ? 'false' : null}
      className={`bbFormGroup${
        props.optional && !props.value ? ' optionalFormControl' : ''
      }`}
    >
      <Form.Control
        as={props.as || 'input'}
        type={showPassword ? 'text' : props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={
          !props.parent
            ? props.handleChange
            : (e) => props.handleChange(e, props.parent)
        }
        onBlur={props.handleChange}
        onKeyPress={onKeyPress}
        className={`${props.className ? `${props.className} ` : ''}${
          props.optional && !props.value ? 'optionalFormControl' : ''
        }${props.type === 'password' ? 'passwordInput' : ''}`}
        isInvalid={props.showValidationBeforeSubmit || isInvalid}
        min={props.min}
        autoComplete={props.autoComplete || 'on'}
        aria-invalid={isInvalid}
        aria-describedby={`${props.controlId}-feedback`}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus}
      />
      {/* label is after the form control to allow css sibling styles on input focus */}
      {props.label ? (
        <Form.Label
          className={`${
            props.value ? 'labelWithInput ' : 'labelWithoutInput '
          }${props.optional ? 'optionalFormLabel' : ''}`}
        >
          {props.label}
        </Form.Label>
      ) : null}

      <FormControl.Feedback
        type={isInvalid ? 'invalid' : 'valid'}
        id={`${props.controlId}-feedback`}
      >
        {renderHelpBlock(isInvalid, props.helpText)}
      </FormControl.Feedback>
      {props.type === 'password' ? (
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          title={showPassword ? 'Hide password' : 'Show password'}
          onClick={toggleShowPassword}
          onKeyPress={toggleShowPassword}
          tabIndex='0'
        />
      ) : null}
    </Form.Group>
  );
};

export default FieldGroup;
