import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import WebpImage from '../webp-image';
import MoneyUtils from '../../services/MoneyUtils';
import './style.css';

const getPlanImage = (months, minimumMonths) => {
  let imageName = 'subSelectionMonthly';
  if (months === '12' || minimumMonths === 12) {
    imageName = 'subSelection12';
  } else if (months === '9' || minimumMonths === 9) {
    imageName = 'subSelection9';
  } else if (months === '6' || minimumMonths === 6) {
    imageName = 'subSelection6';
  } else if (months === '3') {
    imageName = 'subSelection3';
  }
  return imageName;
};

const getHighlightClassName = (highlight) => {
  if (highlight.className) {
    return `subBoxSubHeader left-align ${highlight.className}`;
  }
  return 'subBoxSubHeader left-align';
};

const formatBundledPricing = (price) => {
  const slicedPrice = price.split('.');

  return (
    <>
      <sup>$</sup>
      {slicedPrice[0]}
      <sup>{slicedPrice[1] !== '00' ? slicedPrice[1] : ''}</sup>
    </>
  );
};

const getPinkBoxMessage = (props, selectSubMessage) => {
  if (
    props.referralCode &&
    props.plan.title !== 'Month to Month' &&
    props.plan.months === 'INFINITY'
  ) {
    return `100% off First Box!`;
  }

  if (selectSubMessage) return selectSubMessage;
  if (props.plan.originalCost !== props.plan.costWithCoupon) {
    if (props.plan.couponToAutoPopulate) {
      if (
        props.plan.couponToAutoPopulate.discountPercentage &&
        props.plan.couponToAutoPopulate.discountPercentage > 0
      ) {
        return `${props.plan.couponToAutoPopulate.discountPercentage}% Off First Box!`;
      }
    }
    return `$${props.plan.costWithCoupon} First Box!`;
  }
  return <span>&nbsp;</span>;
};

const renderSubscriptionTag = (plan) => {
  if (plan.tagType === 'BEST_VALUE') {
    return <div className='subscriptionTag'>Best Value</div>;
  }
  if (plan.tagType === 'MOST_POPULAR') {
    return <div className='subscriptionTag'>Most Popular</div>;
  }
  return null;
};

const renderPlanBulletPoints = (props) => {
  if (!props.plan.highlights || !props.plan.highlights.length) {
    return null;
  }
  // eslint-disable-next-line consistent-return, array-callback-return
  const bulletPoints = props.plan.highlights.filter((elem) => {
    // Different points get displayed depending on if this is a referral purchase or not
    if (elem.showForReferralsOrNotOrBoth === 'both') {
      return elem;
    }
    if (elem.showForReferralsOrNotOrBoth === 'yes' && props.referralCode) {
      return elem;
    }
    if (elem.showForReferralsOrNotOrBoth === 'no' && !props.referralCode) {
      return elem;
    }
  });
  return (
    <ul className='bulletpointContainer'>
      {bulletPoints
        .sort((a, b) => a.order - b.order)
        .map((item) => {
          return (
            <li
              key={props.plan._id + item.text}
              className={getHighlightClassName(item)}
            >
              {item.text}
            </li>
          );
        })}
    </ul>
  );
};

const getPlanAsClassName = (plan) => {
  let planClassName = `${plan.title}${plan.months}`;
  planClassName = planClassName.replace(/\s+/g, '');

  return planClassName;
};

const SelectSubscription = (props) => {
  if (!props.plan) {
    return null;
  }

  let subscriptionCouponFromURLThatMatchesThisPlan;
  let planInfoFromCoupon;
  if (props.subscriptionCouponsFromURL) {
    subscriptionCouponFromURLThatMatchesThisPlan = props.subscriptionCouponsFromURL.find(
      (coupon) => {
        return coupon.exclusivePlans.find(
          (exclusivePlan) => exclusivePlan._id === props.plan._id,
        );
      },
    );
    if (subscriptionCouponFromURLThatMatchesThisPlan) {
      planInfoFromCoupon =
        subscriptionCouponFromURLThatMatchesThisPlan.planInfoOverride;
    }
  }

  const planClassName = getPlanAsClassName(props.plan);
  let buttonClassName = `subSelectButton ${planClassName}`;
  if (props.activeIndex === props.planIndex) {
    buttonClassName = `${buttonClassName} subSelectButtonActiveInCarousel`;
  }
  const selectSubMessage = planInfoFromCoupon?.selectSubMessage
    ? planInfoFromCoupon.selectSubMessage
    : props.plan.selectSubMessage;

  const getSubContainerClass = (plan) => {
    return `subContainer subContainer${getPlanAsClassName(plan)}`;
  };

  const isInfinity = props.plan.months === 'INFINITY';
  const isMonthToMonth = props.plan.title === 'Month to Month';
  const isInfinityWithoutMonthToMonth = isInfinity && !isMonthToMonth;

  let discountPrice;
  if (props.referralCode && isInfinityWithoutMonthToMonth) {
    discountPrice = 0;
  } else if (planInfoFromCoupon?.costWithCoupon) {
    discountPrice = MoneyUtils.formatAsMoney(planInfoFromCoupon.costWithCoupon);
  } else {
    discountPrice = MoneyUtils.formatAsMoney(props.plan.costWithCoupon);
  }

  return (
    <>
      <Col className={getSubContainerClass(props.plan)}>
        {renderSubscriptionTag(props.plan)}
        <Row>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            style={{ margin: '24px auto 0 auto' }}
          >
            <WebpImage
              imageName={getPlanImage(
                props.plan.months,
                props.plan.minimumMonths,
              )}
              width='300'
              height='180'
              className='subSelectionImage'
              fluid
              alt={`${props.plan.title} Subscription Plan`}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mb-0'>
            <h3 className='subTitle'>{props.plan.title} Subscription</h3>
            {isMonthToMonth && (
              <Row className='no-gutters'>
                <Col>
                  <h4 className='subPlanPrice subFirstBoxPrice'>
                    ${discountPrice}
                  </h4>
                  <h5>Per Month</h5>
                </Col>
              </Row>
            )}
            {isInfinityWithoutMonthToMonth && (
              <Row className='no-gutters'>
                <Col>
                  <h4 className='subPlanPrice subFirstBoxPrice'>
                    ${discountPrice}
                  </h4>
                  <h5>First Month</h5>
                  <div className='subPlanCost mb-2'>
                    ${props.plan.originalCost} monthly renewal
                  </div>
                </Col>
              </Row>
            )}
            {!isInfinity && (
              <>
                <div className='subBoxHeader1 center-align'>
                  <sup>$</sup>
                  {props.plan.originalCost}
                </div>
                <div className='subPlanPrice inline-text extremelyBold textHighlight'>
                  {formatBundledPricing(discountPrice)}
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row className='subBoxSubContainer'>
          <Col xs={12} sm={12} md={12} lg={12}>
            {renderPlanBulletPoints(props)}
            <Row className='mt-2 center-align'>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                className={`inline-text first-box-discount ${
                  props.plan.originalCost !== props.plan.costWithCoupon ||
                  selectSubMessage
                    ? 'first-box-discount-with-contents'
                    : ''
                }`}
              >
                {getPinkBoxMessage(props, selectSubMessage)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Button
              variant='primary'
              className={buttonClassName}
              onClick={() =>
                props.nextStep(
                  props.plan,
                  subscriptionCouponFromURLThatMatchesThisPlan,
                )
              }
              aria-label={`Add ${props.plan.title} ${
                isInfinity ? '' : 'Bundled '
              }Subscription Plan to cart`}
            >
              SELECT PLAN{' '}
              <FontAwesomeIcon icon={faArrowRight} size='lg' className='ml-2' />
            </Button>
            {isInfinityWithoutMonthToMonth && (
              <p className='selectSubNote'>Cancel or pause at any time.</p>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SelectSubscription;
