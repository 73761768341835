/* eslint-disable react/jsx-props-no-spreading */
// CheckoutForm.js
import React from 'react';
import { Button, Col, Form, Row, Alert } from 'react-bootstrap';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from 'react-stripe-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Loading from '../loading';
import DisplayCheckboxesOrNot from '../terms-and-signUp-checkboxes';

import './style.css';

class InjectedPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumberEmpty: true,
      cardCvcEmpty: true,
      cardExpiryEmpty: true,
      postalCodeEmpty: true,
    };
  }

  handleBlur = (e) => {
    this.setState({ [e.elementType]: false });
    if (!this.state.cardNumberEmpty) this.setState({ cardNumber: true });
    if (!this.state.cardCvcEmpty) this.setState({ cardCvc: true });
    if (!this.state.cardExpiryEmpty) this.setState({ cardExpiry: true });
    if (!this.state.postalCodeEmpty) this.setState({ postalCode: true });
  };

  handleFocus = (e) => {
    this.setState({ [e.elementType]: true });
  };

  handleReady = (e) => {
    this.setState({ [e.elementType]: true });
  };

  handleOnChange = (e) => {
    this.props.onChange(e);
    const typeIsEmpty = `${e.elementType}Empty`;
    if (e.empty) {
      this.setState({ [e.elementType]: false, [typeIsEmpty]: true });
    } else {
      this.setState({ [e.elementType]: true, [typeIsEmpty]: false });
    }
  };

  createOptions = () => ({
    style: {
      base: {
        fontSize: '18px',
        fontWeight: '400',
        color: '#231F20',
        letterSpacing: '0.025em',
        fontFamily: '"Open Sans", Helvetica, sans-serif',
        '::placeholder': {
          color: 'black',
        },
      },
      invalid: {
        color: '#E00007',
      },
    },
  });

  displayCreditCardFormWithBilling = () => {
    return (
      <>
        {this.CreditCardForm()}
        {this.displayBillingAddressToggle()}
      </>
    );
  };

  displayCheckboxes = () => {
    return (
      <div className='checkbox-container'>
        {this.props.isGuestCheckout ? (
          <DisplayCheckboxesOrNot
            toggleSubscriptionToNewsletter={
              this.props.toggleSubscriptionToNewsletter
            }
            toggleTermsAndConditions={this.props.toggleTermsAndConditions}
            isHideDisplayCheckboxes={this.props.isHideDisplayCheckboxes}
            formValuesValidationState={this.props.formValuesValidationState}
            hasBeenSubmitted={this.props.hasBeenSubmitted}
            formValues={this.props.formValues}
            handleChange={this.props.handleChange}
            termsAndConditionsError={this.props.termsAndConditionsError}
          />
        ) : null}
      </div>
    );
  };

  displayBillingAddressToggle = () => {
    if (this.props.hideAllBillingAddressToggle) {
      return null;
    }
    return (
      <Row className='billing-address-container'>
        <Col>
          {!this.props.hideBillingSameAsShipping ? (
            <Form.Group className='billingAddressLabel'>
              <Form.Check
                type='radio'
                key='billingSameAsShipping'
                id='billingSameAsShipping'
                name='billingAddress'
                label='Billing same as shipping address'
                defaultChecked={this.props.isBillingSameAsShipping}
                onChange={this.props.toggleIsBillingSameAsShipping}
              />
              <hr />
              <Form.Check
                type='radio'
                key='billingDifferentFromShipping'
                id='billingDifferentFromShipping'
                name='billingAddress'
                label='Use a different billing address'
                defaultChecked={!this.props.isBillingSameAsShipping}
                onChange={this.props.toggleIsBillingSameAsShipping}
              />
            </Form.Group>
          ) : (
            <p>Billing Address:</p>
          )}
        </Col>
        {this.props.displayBilling()}
      </Row>
    );
  };

  displayPayButton = () => {
    return (
      <div className='pt-2'>
        {this.props.isEdit ? (
          <Row className='show-grid'>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Button
                className='creditCardSubmitBtn'
                disabled={this.props.hasBeenSubmitted}
                variant='primary'
                onClick={(ev) => this.props.goToNextStep(ev, this.props)}
              >
                {this.props.isLoading ? <Loading /> : <div>SUBMIT</div>}
              </Button>
            </Col>
          </Row>
        ) : (
          <Col md={12} lg={12}>
            {this.props.unknownError ? (
              <Col className='form-error'>
                <Alert variant='danger'>{this.props.unknownError}</Alert>
              </Col>
            ) : null}
            <Button
              className='place-order place-credit-card-order'
              disabled={this.props.hasBeenSubmitted}
              onClick={(ev) => this.props.goToNextStep(ev, this.props)}
            >
              {this.props.isLoading ? (
                <Loading />
              ) : (
                <>
                  <FontAwesomeIcon icon={faLock} />
                  {`PAY${
                    this.props.totals && this.props.totals.total
                      ? ` $${this.props.totals.total}`
                      : ''
                  }`}
                </>
              )}
            </Button>
          </Col>
        )}
      </div>
    );
  };

  CreditCardForm = () => {
    return (
      <form className='credit-card-form-component cardDetails'>
        <Row className='show-grid'>
          <Col md={12} lg={12} className='creditCard-Col'>
            <span className={this.state.cardNumber ? 'cardNumber' : ''}>
              {' '}
              Card number{' '}
            </span>
            <CardNumberElement
              className='checkoutCardNumber stripeInput form-control'
              placeholder=''
              onBlur={this.handleBlur}
              onChange={(e) => this.handleOnChange(e)}
              onFocus={this.handleFocus}
              onReady={this.handleReady}
              {...this.createOptions()}
            />
            <p className='helperText'>Prepaid credit cards are not accepted</p>
          </Col>
          <Col md={4} lg={4} className='creditCard-Col'>
            <span className={this.state.cardExpiry ? 'cardExpiry' : ''}>
              {' '}
              Exp. Date{' '}
            </span>
            <CardExpiryElement
              className='checkoutExpirationDate stripeInput form-control'
              placeholder=''
              onBlur={this.handleBlur}
              onChange={(e) => this.handleOnChange(e)}
              onFocus={this.handleFocus}
              onReady={this.handleReady}
              {...this.createOptions()}
            />
          </Col>
          <Col md={4} lg={4} className='creditCard-Col'>
            <span className={this.state.cardCvc ? 'cardCvc' : ''}> CVC </span>
            <CardCVCElement
              className='checkoutCvc stripeInput form-control'
              placeholder=''
              onBlur={this.handleBlur}
              onChange={(e) => this.handleOnChange(e)}
              onFocus={this.handleFocus}
              onReady={this.handleReady}
              {...this.createOptions()}
            />
          </Col>
          <Col md={4} lg={4} className='creditCard-Col'>
            <span className={this.state.postalCode ? 'postalCode' : ''}>
              {' '}
              Zip Code{' '}
            </span>
            <PostalCodeElement
              className='checkoutPostalCode stripeInput form-control'
              placeholder=''
              onBlur={this.handleBlur}
              onChange={(e) => this.handleOnChange(e)}
              onFocus={this.handleFocus}
              onReady={this.handleReady}
              {...this.createOptions()}
            />
          </Col>
        </Row>
        <div className='pt-2' />
        {this.props.error ? (
          <div className='form-error'>
            <Alert variant='danger'>{this.props.error}</Alert>
          </div>
        ) : null}
      </form>
    );
  };

  render() {
    if (this.props.loadingStripe) {
      return (
        <div>
          <div>Getting ready for your secure payment info...</div>
          <div className='payment-loader' />
        </div>
      );
    }

    if (this.props.isEdit) {
      return (
        <div>
          {this.CreditCardForm()}
          {this.displayCheckboxes()}
          {this.displayPayButton()}
        </div>
      );
    }

    return (
      <div>
        {this.CreditCardForm()}
        {this.displayBillingAddressToggle()}
        {this.displayCheckboxes()}
        {this.displayPayButton()}
      </div>
    );
  }
}

export default injectStripe(InjectedPaymentForm);
