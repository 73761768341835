import Environment from './Environment';
import Content from '../constants/Content';
import serviceUtils from './serviceUtils';
import Logger from './Logger';

const addUserProfile = (userData, source) => {
  try {
    const _learnq = window._learnq || [];
    const today = new Date();
    let points = 0;
    let expiredPoints = 0;
    let usedPoints = 0;
    let unexpiredPoints = 0;
    if (userData.points) {
      userData.points.forEach((pointData) => {
        if (pointData.pointsDifference < 0) {
          usedPoints += pointData.pointsDifference;
        } else if (pointData.expirationDate < today) {
          expiredPoints += pointData.pointsDifference;
        } else {
          unexpiredPoints += pointData.pointsDifference;
        }
      });
      if (expiredPoints + usedPoints >= 0) points = unexpiredPoints;
      else points = unexpiredPoints + expiredPoints + usedPoints;
    }

    if (userData && (userData.email || userData.guestCheckoutEmail)) {
      // Identifying a person and tracking special Klaviyo properties.
      const phoneNumberInParts = serviceUtils.getCountryAndAreaCodeAndNumber(
        userData.phoneNumber,
      );

      // double check email consent
      const klaviyoUserData = {
        $email: userData.email || userData.guestCheckoutEmail,
        $first_name: userData.name ? userData.name.first : '', // eslint-disable-line camelcase
        $last_name: userData.name ? userData.name.last : '', // eslint-disable-line camelcase
        $phone_number: `+${phoneNumberInParts.countryCode}${phoneNumberInParts.areaCode}${phoneNumberInParts.phoneNumber}`, // eslint-disable-line camelcase
        isSubscribedToNewsletter: true,
        Points: points,
      };
      if (userData.isSubscribedToNewsletter) {
        klaviyoUserData.$consent = ['email'];
      }
      if (source) {
        const isGeneralOptIn =
          source === 'WebsiteFooter' || source === 'Checkout';
        const optInKey = isGeneralOptIn ? 'OptIn_General' : `OptIn_${source}`;
        const optInSourceKey = `OptInSource_${source}Page`;
        klaviyoUserData[optInKey] = userData.isSubscribedToNewsletter || false;
        klaviyoUserData[optInSourceKey] = true;
      }
      _learnq.push(['identify', klaviyoUserData]);
    }
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const updateUserPoints = (user, points) => {
  try {
    const _learnq = window._learnq || [];
    if (user) {
      _learnq.push([
        'identify',
        {
          $email: user.email || user.guestCheckoutEmail,
          Points: points,
        },
      ]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const identifyUser = (userInfo) => {
  try {
    const _learnq = window._learnq || [];

    if (userInfo && userInfo.email) {
      _learnq.push([
        'identify',
        {
          $email: userInfo.email,
          $source: userInfo.source,
        },
      ]);

      _learnq.push([
        'track',
        'Created',
        {
          url: window.location.href,
        },
      ]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const viewedProduct = (product) => {
  try {
    const _learnq = window._learnq || [];

    const mediaUrl = serviceUtils.getProductImageByColor(product.media, 'NONE');
    const viewedProductData = {
      SKU: product.sku,
      ProductName: product.name,
      ItemPrice: product.actualCost,
      ProductURL: `${
        Environment.getSelfDomain
      }/products/${product.urlName?.toLowerCase()}`,
      ImageURL: mediaUrl,
      ID: product._id,
      ViewedOn: new Date(),
    };
    _learnq.push(['track', 'Viewed Product', viewedProductData]);
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const viewedSubscription = (plan) => {
  try {
    const _learnq = window._learnq || [];

    _learnq.push([
      'track',
      'Viewed Subscription',
      {
        Plan:
          plan.sku || `${Content.names.shortName} ${plan.title} Subscription`,
        $value: plan.costWithCoupon || plan.actualCost,
        ViewedOn: new Date(),
      },
    ]);
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const determineSubscriptionField = (cart, cartData) => {
  const cartDataObj = cartData;
  if (cart.subscription.plan.title === 'Month to Month') {
    cartDataObj.hasMontlySubscription = true;
  } else if (cart.subscription.plan.title === 'Six Month') {
    cartDataObj.has6MonthSubscription = true;
  } else if (cart.subscription.plan.title === 'Nine Month') {
    cartDataObj.has9MonthSubscription = true;
  } else if (cart.subscription.plan.title === 'Twelve Month') {
    cartDataObj.has12MonthSubscription = true;
  }
  return cartDataObj;
};

const updatedCart = (cart) => {
  try {
    const _learnq = window._learnq || [];
    const includeViewedOn = true;
    const items = serviceUtils.getDataForCart(cart, includeViewedOn);
    if (items && items.length) {
      const hasPumpUpsell = items.some((item) => item.isBreastPump);
      let cartData = {
        $value: cart.subCosts ? cart.subCosts.overallTotal : 0,
        Items: items,
        UpdatedOn: new Date(),
        Provider: Environment.store,
        hasSubscription: !!cart.subscription,
        hasPumpUpsell,
      };

      if (cart.subscription) {
        cartData = determineSubscriptionField(cart, cartData);
      }

      _learnq.push(['track', 'Updated Cart', cartData]);
      return _learnq;
    }
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const setDueDateForUser = (date, email, optInSource) => {
  try {
    const _learnq = window._learnq || [];
    let dateObject;
    if (typeof date !== 'object') {
      dateObject = new Date(date);
    } else {
      dateObject = date;
    }
    if (typeof dateObject === 'object' && email) {
      const eventData = {
        $email: email,
        DueDate: dateObject,
      };

      if (optInSource) {
        eventData[`OptInSource_${optInSource}`] = true; // eslint-disable-line camelcase
        eventData.OptIn_DueDateCalculator = true; // eslint-disable-line camelcase
      }

      _learnq.push(['identify', eventData]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const checkoutStarted = (cart) => {
  try {
    const _learnq = window._learnq || [];
    const includeViewedOn = true;

    const items = serviceUtils.getDataForCart(cart, includeViewedOn);
    if (items && items.length) {
      let cartData = {
        $value: cart.subCosts ? cart.subCosts.overallTotal : 0,
        Items: items,
        StartedAt: new Date(),
        Provider: Environment.store,
        hasSubscription: !!cart.subscription,
      };

      if (cart.subscription) {
        cartData = determineSubscriptionField(cart, cartData);
      }

      _learnq.push(['track', 'Checkout Started', cartData]);
      return _learnq;
    }
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const viewPartner = (email, partner) => {
  try {
    const _learnq = window._learnq || [];

    if (partner && email) {
      _learnq.push([
        'identify',
        {
          $email: email,
          viewedBPPartner: true,
          BreastPumpStep: 4,
        },
      ]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const addRegistryToUser = (email, dueDate) => {
  try {
    const _learnq = window._learnq || [];

    if (email) {
      _learnq.push([
        'identify',
        {
          $email: email,
          hasRegistry: true,
          DueDate: dueDate,
        },
      ]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const breastPumpProcess = (stepNum, email, bpData) => {
  try {
    const _learnq = window._learnq || [];

    if (email) {
      let klaviyoUpdateData = {};
      if (stepNum === 1) {
        const phoneNumberInParts = serviceUtils.getCountryAndAreaCodeAndNumber(
          bpData.phoneNumber,
        );
        const formattedPhoneNumber = `+${phoneNumberInParts.countryCode}${phoneNumberInParts.areaCode}${phoneNumberInParts.phoneNumber}`;
        klaviyoUpdateData = {
          firstName: bpData.firstName,
          lastName: bpData.lastName,
          phoneNumber: formattedPhoneNumber,
          $first_name: bpData.firstName, // eslint-disable-line camelcase
          $last_name: bpData.lastName, // eslint-disable-line camelcase
          $phone_number: formattedPhoneNumber, // eslint-disable-line camelcase
          DueDate: bpData.dueDate,
          isSubscribedToNewsletter: bpData.isSubscribedToNewsletter,
        };
        if (bpData.isSubscribedToNewsletter) {
          klaviyoUpdateData.$consent = ['email'];
        }
      }
      if (stepNum === 2) {
        klaviyoUpdateData = {
          city: bpData.city,
          state: bpData.state,
          country: bpData.country,
          zipCode: bpData.zipCode,
          $city: bpData.city,
          $region: bpData.state,
          $country: bpData.country,
          $zip: bpData.zipCode,
        };
      }
      if (stepNum === '3A') {
        klaviyoUpdateData = {
          insuranceType: bpData.insuranceType,
          BreastPumpPartner: bpData.matchedPartner,
          isAgreedToMomEssentials: bpData.isAgreedToMomEssentials,
        };
      }
      klaviyoUpdateData.$email = email;
      klaviyoUpdateData.BreastPumpStep = stepNum;
      _learnq.push(['identify', klaviyoUpdateData]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const breastPumpPartnerApiInfoSent = (email, partner) => {
  try {
    const _learnq = window._learnq || [];

    if (partner && email) {
      _learnq.push([
        'identify',
        {
          $email: email,
          BPPartnerApiSent: true,
          BreastPumpStep: 4,
        },
      ]);
    }

    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const registryProcess = (stepNum, email, registryCode) => {
  try {
    const _learnq = window._learnq || [];

    if (email) {
      _learnq.push([
        'identify',
        {
          $email: email,
          RegistryStep: stepNum,
          Registry: registryCode,
        },
      ]);
    }
    return _learnq;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

export default {
  addUserProfile,
  identifyUser,
  viewedProduct,
  viewedSubscription,
  updatedCart,
  updateUserPoints,
  setDueDateForUser,
  checkoutStarted,
  viewPartner,
  addRegistryToUser,
  breastPumpProcess,
  breastPumpPartnerApiInfoSent,
  registryProcess,
};
