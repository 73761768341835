/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import BBAnalytics from '../../services/BBAnalytics';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    BBAnalytics.pageViewEvent(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      let page = this.props.location.pathname;
      if (page.includes('/purchase/thank-you/')) {
        page = '/purchase/thank-you';
      }

      trackPage(page);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      // eslint-disable-next-line
      return <WrappedComponent {...this.props} {...options} />;
    }
  };

  return HOC;
}
